import React, { Dispatch, SetStateAction } from 'react';
import { MdCheck } from 'react-icons/md';
import { EpcGraph, Loading } from '@epcbuilder/lib/components';
import usePropertyEpc from '@epcbuilder/lib/hooks/properties/usePropertyEPC';
import { CreateAddress } from '@epcbuilder/lib/models/properties';
import { format } from 'date-fns';
import { FormStage } from '@/models/generic';

const EpcStage = ({
  address,
  handleBypassEpc,
  setFormStage,
}: {
  address: CreateAddress;
  handleBypassEpc: () => void;
  setFormStage: Dispatch<SetStateAction<FormStage>>;
}) => {
  const { epc, isLoading } = usePropertyEpc({ postcode: address.postcode, addressLine1: address.addressLine1 });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl">Step 3: Current EPC Rating</h1>
        <p>See your EPC rating, property details and more.</p>
      </div>

      {epc ? (
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="border-neutral-lighter border-1 flex w-full flex-col gap-2 rounded-[10px] bg-[#fff] p-4">
            <div className="flex flex-col gap-0.5">
              <div className="flex flex-row items-center gap-2">
                <p className="font-bold">Date of inspection:</p>
                <p>{epc?.ratingCreatedOn && format(new Date(epc?.ratingCreatedOn), 'dd/MM/yyyy')}</p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <p className="font-bold">Valid until:</p>
                <p
                  className={`${
                    epc?.certificateValidUntil && new Date() > new Date(epc?.certificateValidUntil) && 'text-error'
                  }`}
                >
                  {epc?.certificateValidUntil && format(new Date(epc?.certificateValidUntil), 'dd/MM/yyyy')}
                </p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <p className="font-bold">Property type:</p>
                <p>{epc?.propertyType}</p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <p className="font-bold">Built form:</p>
                <p>{epc?.builtForm}</p>
              </div>
            </div>
            <div className="relative w-[300px]">{epc && <EpcGraph propertyRating={epc.currentRating} />}</div>
          </div>
          <div className="flex w-full flex-col gap-2">
            <h2 className="text-2xl">Register Today to:</h2>
            <ul className="flex flex-col gap-1.5">
              <li className="flex flex-row gap-2">
                <MdCheck className="text-primary min-size-6 size-6" />
                <p>Improve the EPC rating</p>
              </li>
              <li className="flex flex-row gap-2">
                <MdCheck className="text-primary size-6 min-w-6" />
                <p>Lower your energy bills</p>
              </li>
              <li className="flex flex-row gap-2">
                <MdCheck className="text-primary size-6 min-w-6" />
                <p>Increase the value of your property</p>
              </li>
              <li className="flex flex-row gap-2">
                <MdCheck className="text-primary size-6 min-w-6" />
                <p>Reduce Co2 emissions</p>
              </li>
              <li className="flex flex-row gap-2">
                <MdCheck className="text-primary size-6 min-w-6" />
                <p>Receive a bespoke Property Improvement Plan</p>
              </li>
              <li className="flex flex-row gap-2">
                <MdCheck className="text-primary size-6 min-w-6" />
                <p>Get a rebate of the assessment cost (£195)</p>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div>
            <h2>We are unable to locate an EPC rating for address:</h2>
            <p>{address.addressLine1}</p>
            {address.addressLine2 && <p>{address.addressLine2}</p>}
            <p>{address.city}</p>
            <p>{address.postcode}</p>
          </div>
          <div>
            <p>This may be because:</p>
            <p>
              1. The property does not have an EPC,{' '}
              <button
                type="button"
                className="text-link"
                onClick={() => {
                  handleBypassEpc();
                  setFormStage(FormStage.USER);
                }}
              >
                click here to continue
              </button>
            </p>
            <p>2. You have recently had an EPC done, if so, please contact us on 0800 058 4140</p>
            <p>
              3. The address is incorrect,{' '}
              <button type="button" className="text-link" onClick={() => setFormStage(FormStage.POSTCODE)}>
                click here to edit your address
              </button>
            </p>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-4 sm:flex-row">
        <button
          className="text-dark-dark bg-light enabled:hover:border-blue-dark border-blue enabled:hover:shadow-grey-sm h-14 w-full rounded-[20px] border-2 font-bold"
          onClick={() => setFormStage(FormStage.POSTCODE)}
        >
          Edit Your Address
        </button>
        <button
          className="text-dark-dark bg-blue enabled:hover:border-blue-dark border-blue enabled:hover:shadow-grey-sm h-14 w-full rounded-[20px] border-2 font-bold"
          onClick={() => {
            if (!epc) handleBypassEpc();
            setFormStage(FormStage.USER);
          }}
        >
          Lets Go!
        </button>
      </div>
    </div>
  );
};

export default EpcStage;
